import React, { useContext } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Svg404 from '../components/svg/404/error404'
import SvgShards from '../components/svg/404/shards'
import SvgBackground from '../components/svg/404/background'

import FadeLink from '../components/fade-link'

import ThemeContext from '../context/theme-context'

const NotFoundPage = () => {
    const context = useContext(ThemeContext)

    return (
        <Layout>
            <SEO title="404: Not found" />
            <div className="error404">
                <div className="error404-content">
                    <Svg404 />
                    <p className="error404-text">
                    Oops! Parece que este conteúdo não existe mais.
                        Não se preocupe, você pode voltar a navegar apenas clicando no link.
                    </p>
                    <div className="button-group">
                        <FadeLink
                            link="/"
                            className="btn btn-home"
                        >
                            home
                        </FadeLink>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
