import React from 'react';

const error404 = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.8 198.27">
            <g id="Camada_2" data-name="Camada 2">
                <g id="Camada_1-2" data-name="Camada 1" width="100%" height="100%">
                    <path
                        d="M104.62,198.27H70.24A15,15,0,0,1,55.3,183.33V158.67c0-8.22,14.94-14.95,14.94-14.95A14.56,14.56,0,0,0,84.94,129v-15.2a9.57,9.57,0,0,0-9.72-9.71L15,103.87A14.92,14.92,0,0,1,1.49,82.45l50.07-76A14,14,0,0,1,63.52,0h26.4a15,15,0,0,1,14.94,15l-.24,115.82c0,5.48,7.72,12.95,7.72,12.95a14.78,14.78,0,0,1,7.22,12.71v26.9A15,15,0,0,1,104.62,198.27ZM85.19,29.89a10,10,0,0,0-10-10H71.49a9.61,9.61,0,0,0-8.22,4.48L31.14,73.48a7.65,7.65,0,0,0-.75,3.24,7.34,7.34,0,0,0,7.47,7.47H70.24a15,15,0,0,0,15-14.94Z"
                        fill="#00B01E"
                    />
                    <path
                        d="M242.11,198.27H152.94A15,15,0,0,1,138,183.33V15A14.78,14.78,0,0,1,152.94.25h89.17A14.73,14.73,0,0,1,256.81,15V183.33A14.94,14.94,0,0,1,242.11,198.27ZM172.62,19.93a14.78,14.78,0,0,0-14.7,14.94v94.41L222.18,19.93Zm64.51,14.94L172.62,143.72h49.56A14.78,14.78,0,0,0,237.13,129Z"
                        fill="#00B01E"
                    />
                    <path
                        d="M379.85,198.27H345.48a15,15,0,0,1-14.94-14.94V158.67c0-8.22,14.94-14.95,14.94-14.95A14.56,14.56,0,0,0,360.18,129v-15.2a9.57,9.57,0,0,0-9.72-9.71l-60.28-.25a14.92,14.92,0,0,1-13.45-21.42l50.07-76a14,14,0,0,1,12-6.48h26.4A15,15,0,0,1,380.1,15l-.25,115.82c0,5.48,7.73,12.95,7.73,12.95a14.78,14.78,0,0,1,7.22,12.71v26.9A15,15,0,0,1,379.85,198.27ZM360.43,29.89a10,10,0,0,0-10-10h-3.73a9.61,9.61,0,0,0-8.22,4.48L306.38,73.48a7.65,7.65,0,0,0-.75,3.24,7.34,7.34,0,0,0,7.47,7.47h32.38a15,15,0,0,0,14.95-14.94Z"
                        fill="#00B01E"
                    />
                </g>
            </g>
        </svg>
    )
}

export default error404
